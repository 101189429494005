import { Canvas } from "@react-three/fiber";
import React, { Suspense, lazy } from "react";

import Header from "./Header";
import HeroSection from "./HeroSection";
// import Globe from "./Globe";
// import Shape from "./Shape";
import Skills from "./Skills";
import Footer from "./Footer";
import AboutMe from "./AboutMe";
import Projects from "./Project";
import ContactMe from "./ContactMe";
import Internships from "./Internships";
import Clubs from "./Clubs";

const Globe = lazy(() => import("./Globe"));
const Shape = lazy(() => import("./Shape"));

const GlobeCanvas = () => (
  <Suspense>
    <Canvas style={{ width: "100%" }}>
      <ambientLight intensity={Math.PI / 2} />
      <spotLight
        position={[10, 10, 10]}
        angle={0.15}
        penumbra={1}
        decay={0}
        intensity={Math.PI}
      />
      <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
      <Globe position={[-0.5, 1.7, -4.2]} />
    </Canvas>
  </Suspense>
);

const ShapeCanvas = ({ style, position }) => (
  <Suspense>
    <Canvas style={style}>
      <ambientLight intensity={Math.PI / 2} />
      <spotLight
        position={[10, 10, 10]}
        angle={0.15}
        penumbra={1}
        decay={0}
        intensity={Math.PI}
      />
      <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
      <Shape position={position} />
    </Canvas>
  </Suspense>
);

const FrontPage = () => {
  return (
    <div className="overflow-hidden">
      <Header linkedLogo={false} />
      <HeroSection boxComponent={<GlobeCanvas />} />
      <Internships id="projects" />
      <Clubs id="clubs" />
      <Projects id="projects" />
      <AboutMe
        style={{ width: "50vw", height: "50vh" }}
        boxComponent={
          <ShapeCanvas style={{ width: "100%" }} position={[-0.5, 0, -4.5]} />
        }
      />
      <Skills />
      <ContactMe
        boxComponent={
          <ShapeCanvas
            style={{ width: "50vw", height: "50vh" }}
            position={[-3, 0, -4]}
          />
        }
      />
      <Footer />
    </div>
  );
};

export default FrontPage;
