import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import FrontPage from "./frontpage/Frontpage";
import MusicPage from "./music/Musicpage";
import ResumeFull from "./resume/Resume";
import Research from "./research/Research";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/music" element={<MusicPage />} />
        <Route path="/resume" element={<ResumeFull />} />
        <Route path="/research" element={<Research />} />
      </Routes>
    </Router>
  );
};

export default App;
