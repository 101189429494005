import DownArrow from "../assets/downarrow.svg";
import { Link } from "react-scroll";
import "../index.css";

function HeroSection({ boxComponent }) {
  return (
    <div className="flex w-screen lg:mx-10 h-screen bg-transparent">
      <div className="lg:w-1/2 w-max mt-7 bg-transparent">
        <div className="w-4/5 mx-auto">
          <div className="lg:text-7xl text-5xl font-jetbrains-bolder py-10">
            Hi, my name is Aniruddha
          </div>

          <div className="lg:text-3xl text-xl font-jetbrains-regular pb-10">
            I am currently a junior at the University of Illinois at
            Urbana-Champaign studying Computer Science + Crop Sciences.
          </div>

          <div className="hover:cursor-pointer flex text-sm font-jetbrains-regular hover:translate-y-1 transition-transform select-none pb-auto">
            <Link to="projects" smooth={true} duration={500}>
              <img className="min-w-1 max-w-12" src={DownArrow} alt=""></img>
              Learn More
            </Link>
          </div>
        </div>
      </div>

      <div className="lg:w-1/2 lg:block hidden">{boxComponent}</div>
    </div>
  );
}

export default HeroSection;
