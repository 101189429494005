import {
  Scene,
  Color,
  PerspectiveCamera,
  WebGLRenderer,
  PlaneGeometry,
  TextureLoader,
  MeshBasicMaterial,
  Mesh,
  Raycaster,
  Vector2,
} from "three";

import Sami1 from "../valentines/sami_pics/2F400CBA-099A-4CDA-97AD-82C7DA840A12.JPG";
import Sami2 from "../valentines/sami_pics/8EFA1A63-244C-4140-BCC4-6ED857F12C69.JPG";
import Sami3 from "../valentines/sami_pics/23265BE5-018C-45B3-9618-B3A9DBB33701.JPG";
import Sami4 from "../valentines/sami_pics/69216070677__E575AB99-441E-4547-950A-AE6363F8BDB8.jpg";
import Sami5 from "../valentines/sami_pics/70684169968__72B5C8D4-4D1F-47F1-9B66-E3DAC9071005.jpg";

const THREE = {
  Scene,
  Color,
  PerspectiveCamera,
  WebGLRenderer,
  TextureLoader,
  PlaneGeometry,
  MeshBasicMaterial,
  Mesh,
  Raycaster,
  Vector2,
};

const Gallery = () => {
  // SETTING THE SCENE
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  scene.background = new THREE.Color(0x0f0f0f);
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);

  // CREATING THE RAYCASTING MOUSE INTERACTIONS

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  function onMouseMove(event) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      const object = intersects[0].object;
      console.log("Hovering over:", object);
      object.material.color.set(0xf0ff0f);
      object.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z - 100
      );
    }
  }

  function onMouseClick(event) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      const object = intersects[0].object;
      console.log("Clicked on:", object);
    }
  }

  window.addEventListener("mousemove", onMouseMove, false);
  window.addEventListener("click", onMouseClick, false);

  const generateAlbumCover = async (image, x, y, z) => {
    // const texture = new THREE.TextureLoader().load(image);
    const planeGeometry = new THREE.PlaneGeometry(150, 150);
    // const material = new THREE.MeshBasicMaterial({ map: texture });
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 }); // Initial green color

    const albumCover = new THREE.Mesh(planeGeometry, material);
    albumCover.position.set(x, y, z);
    scene.add(albumCover);
  };

  const animate = () => {
    requestAnimationFrame(animate);

    // DO

    renderer.render(scene, camera);
  };

  // FINAL FUNCTION CALL SETUPS

  let albumCoverURLS = [Sami1, Sami2, Sami3, Sami4, Sami5];

  for (let i = 0; i < albumCoverURLS.length; i++) {
    generateAlbumCover(albumCoverURLS[i], i * 5, 0, -20);
  }

  animate();

  return (
    <>
      <div className="w-screen h-screen opacity-0 transition-opacity overflow-hidden">
        <div>Testing</div>
      </div>
    </>
  );
};

export default Gallery;
